import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { media } from '../../utilities/helpers'

const colors = {
  bgColor: '#E2E2E2',
  bgDark: '#343434',
}

export const BannerLayout = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 80px 0;
  grid-gap: 10px;
  height: auto;
  min-height: 60vh;
  ${media.lg`
   grid-template-columns : 1fr;
   grid-gap : 45px;
      `}
`
export const BannerLayoutLeft = styled.div<any>`
  padding: 0 30px;
  ${media.lg`
      display : grid;
      place-items : center;
      text-align : center;
      padding:0;
     `};
`
export const BannerLayoutRight = styled.div<any>`
  border: ${({ themeMode }) =>
    themeMode === 'light' ? `8px solid ${colors.bgColor}` : `8px solid ${colors.bgDark}`};
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  :hover {
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light'
        ? `0px 1px 18px ${themeLight.hoverShadow}`
        : `0px 2px 26px ${themeDark.hoverShadow}`};
  }
`
export const BannerText = styled.h1<any>`
  font-weight: 700;
  font-size: 50px;
  color: ${({ theme }) => theme.sectionTitleColor};
  span {
    color: ${({ theme }) => theme.brandColor};
  }
  ${media.lg`
     font-size : 40px;
     `}

  ${media.sm`
      font-size : 34px;
  `}
`
export const BannerDescription = styled.p<any>`
  margin: 30px 0;
  font-weight: 500;
  font-size: 20px;
`
export const BannerButtons = styled.div<any>`
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.md`
   width:100%;
   justify-content:space-around;
     & > * {
       margin : 10px 0;
     }
     `}
`
