import { useAppSelector } from 'hooks/useAppSelector'
import { useGetCategoriesQuery } from 'logic/reactQuery/homePageService'
import Link from 'next/link'
import NextImage from 'shared/components/nextImage/NextImage'
import { FlexBox, LoadingCard, SectionHeader } from '../../styles/defaultStyles'
import {
  CategoriesContainer,
  CategoriesLayout,
  CategoryCard,
  CategoryCardFooter,
  CategoryCardHeader,
} from './Categories.style'

export const Categories = () => {
  const { data, isLoading }: any = useGetCategoriesQuery()
  const { theme } = useAppSelector(state => state.theme)
  return (
    <CategoriesLayout>
      <FlexBox align="center" justify="center">
        <SectionHeader>Categories</SectionHeader>
      </FlexBox>
      <CategoriesContainer>
        {data?.slice(0, 9)?.map((info: any) => (
          <Link href={`/collection/${info.categorySlug}`}>
            <CategoryCard themeMode={theme} key={info?.id}>
              <CategoryCardHeader>
                <NextImage
                  width={60}
                  height={60}
                  src={info?.categoryImageUrl}
                  alt={info?.categoryName}
                />
              </CategoryCardHeader>
              <CategoryCardFooter>{info?.categoryName}</CategoryCardFooter>
            </CategoryCard>
          </Link>
        ))}
        {isLoading &&
          Array.from({ length: 9 }, () => (
            <LoadingCard
              themeMode={theme}
              width={410}
              height={196}
              style={{ marginBottom: '15px' }}
            />
          ))}
      </CategoriesContainer>
    </CategoriesLayout>
  )
}

