import { FlexBox, SectionHeader } from '../../styles/defaultStyles'
import { MeetJungleContainer, MeetJungleLayout, MeetJungleVideo } from './MeetJungle.style'

export const MeetJungle = () => {
  return (
    <MeetJungleLayout>
      <FlexBox align="center" justify="center">
        <SectionHeader>Meet Jungle</SectionHeader>
      </FlexBox>
      <MeetJungleContainer>
        <MeetJungleVideo
          poster="https://jungle-development-bucket.s3.amazonaws.com/profile/1661514562018-pexels-david-ria%C3%B1o-cort%C3%A9s-975771.jpg"
          controls
        >
          <source
            src="https://jungle-development-bucket.s3.amazonaws.com/profile/1661514527324-videoplayback%20%281%29.mp4"
            type="video/mp4"
          />
        </MeetJungleVideo>
      </MeetJungleContainer>
    </MeetJungleLayout>
  )
}

