import { useWeb3React } from '@web3-react/core'
import { useGetTrendingCollectionQuery } from 'logic/reactQuery/collectionsService'
import { useGetCategoriesQuery } from 'logic/reactQuery/homePageService'
import NextImage from 'shared/components/nextImage/NextImage'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import Slider from 'react-slick'
import SelectField from '../../shared/components/selectField/SelectField'
import { SelectFieldStyle } from '../../shared/components/selectField/SelectFieldStyle'
import { trendingSlider } from '../../shared/components/sliderArrows/sliderConfig'
import { FlexBox, LoadingCard, SectionHeader } from '../../styles/defaultStyles'
import {
  SelectDropdown,
  TrendingCard,
  TrendingCardBottom,
  TrendingCardProfileDescription,
  TrendingCardProfileInfo,
  TrendingCardProfileLeft,
  TrendingCardProfileName,
  TrendingCardProfileRight,
  TrendingCardTop,
  TrendingContainer,
  TrendingLayout,
  CashbackButton,
} from './Trending.style'
import { blueTick } from 'shared/icons'
import { useAppSelector } from 'hooks/useAppSelector'
export const Trending = ({ info }: any) => {
  const [selectCategory, setSelectCategory] = useState('')
  const { theme } = useAppSelector(state => state.theme)
  const { isLoading, data }: any = useGetTrendingCollectionQuery(selectCategory)
  const { data: categories, isLoading: categoryLoading } = useGetCategoriesQuery()
  const { homePageStyles } = SelectFieldStyle()
  const handleCategoryChange = useCallback(
    (info: any) => {
      setSelectCategory(info.value)
    },
    [setSelectCategory],
  )

  const categoryOptions =
    !categoryLoading && categories
      ? categories?.map((category: any) => ({
        value: category?.categorySlug,
        label: category?.categoryName,
      }))
      : []
  const firstOptions = {
    value: 'all',
    label: 'All',
  }
  categoryOptions.unshift(firstOptions)

  useEffect(() => {
    if (categoryOptions?.length > 0 && !selectCategory) {
      setSelectCategory(categoryOptions[0]?.value)
    }
  }, [categoryOptions])
  const { account } = useWeb3React()
  const router = useRouter()

  return (
    <>
      <TrendingLayout>
        <FlexBox align="center" justify="center">
          <SectionHeader>Trending</SectionHeader>
        </FlexBox>
        <SelectDropdown>
          <SelectField
            options={categoryOptions}
            handleSelectChange={handleCategoryChange}
            selectedValue={selectCategory}
            dropdownIndicator={true}
            customStyles={homePageStyles}
          />
        </SelectDropdown>
        <TrendingContainer>
          <Slider {...trendingSlider}>
            {!isLoading &&
              data &&
              data?.message &&
              Array.isArray(data?.message) &&
              data?.message?.length > 0 &&
              data?.message?.map((card: any) => (
                <TrendingCard
                  onClick={() => {
                    router.push(`/collections/${card?.slug}`)
                  }}
                  key={card?.id}
                  themeMode={theme}
                >
                  <TrendingCardTop backgroundImage={card?.banner ?? card?.logo}></TrendingCardTop>
                  <TrendingCardBottom>
                    <TrendingCardProfileInfo>
                      <TrendingCardProfileLeft>
                        {card?.logo && <NextImage src={card?.logo} alt="" width={50} height={50} />}
                      </TrendingCardProfileLeft>
                      <TrendingCardProfileRight>
                        <TrendingCardProfileName title={card?.name}>
                          {card?.name}{' '}
                          {card?.isVerified && (
                            <span>
                              <NextImage
                                src={blueTick}
                                alt=""
                                className="img-icon"
                                width="15px"
                                height="15px"
                              />
                            </span>
                          )}
                        </TrendingCardProfileName>
                        {card?.owner?.userName && (
                          <TrendingCardProfileDescription
                            onClick={(e: any) => {
                              e?.stopPropagation()
                              if (
                                account?.toLowerCase() === card?.ownerWalletAddress?.toLowerCase()
                              ) {
                                router.push(`/profile`)
                              } else {
                                router.push(`/profile/${card?.ownerWalletAddress}`)
                              }
                            }}
                          >
                            by {card?.owner?.userName}{' '}
                            {card?.owner?.verifiedUser && (
                              <span>
                                <NextImage
                                  src={blueTick}
                                  alt=""
                                  className="img-icon"
                                  width="12px"
                                  height="12px"
                                />
                              </span>
                            )}
                          </TrendingCardProfileDescription>
                        )}
                      </TrendingCardProfileRight>
                      {card?.hasCashback ? (
                        <CashbackButton card>{card?.cashback}% Cashback</CashbackButton>
                      ) : null}
                    </TrendingCardProfileInfo>
                  </TrendingCardBottom>
                </TrendingCard>
              ))}
            {isLoading &&
              Array.from({ length: 4 }, (x, v) => (
                <LoadingCard themeMode={theme} width={405} height={471} />
              ))}
          </Slider>
        </TrendingContainer>
      </TrendingLayout>
    </>
  )
}

