import styled, { keyframes } from 'styled-components'
import { animateHover } from 'styles/animations'
import { customColors, themeDark, themeLight } from 'styles/theme'
import { media } from 'utilities/helpers'
import { blueTick } from '../../shared/icons'
export const TopCollectionsLayout = styled.div<any>`
  margin: 50px 0;
`

const colors = {
  bgColor: '#181818',
  textColor: '#31a82f',
}
export const TopCollectionsContainer = styled.div<any>`
  /* display: flex; */
  /* align-items: center;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  column-gap: 20px;
  justify-content: space-between;
  margin: 50px 0;
  ${media.sm`
    grid-template-columns:1fr;
  `}
`
export const TopCollectionCard = styled.div<any>`
  background: transparent;
  border: 2px solid ${themeDark.brandColor};
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: auto auto 30px auto;
  cursor: pointer;
  transition: all linear 0.2s;
  :hover {
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light'
        ? `0px 1px 18px ${themeLight.hoverShadow}`
        : `0px 2px 30px ${themeDark.hoverShadow}`};
    animation: ${animateHover} ease-in-out infinite 2s;
  }
`
export const TopCollectionCardImage = styled.div<any>`
  margin-right: 25px;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 11px;
    height: 11px;
    background: ${colors.textColor};
    border-radius: 50%;
    top: -4px;
    right: -6px;
    z-index: 2;
  }
  &::before {
    position: absolute;
    content: '';
    width: 19px;
    height: 19px;
    background: ${({ themeMode }) => (themeMode === 'light' ? themeDark.white : colors.bgColor)};
    border-radius: 50%;
    top: -8px;
    right: -10px;
    z-index: 1;
  }

  img {
    border-radius: 10px !important;
  }

  & > .icons {
    position: absolute;
    width: 22px;
    height: 22px;
    background-image: url('${blueTick.src}');
    border-radius: 50%;
    top: 44px;
    left: 44px;
    background-size: cover;
  }
`
export const TopCollectionCardInfo = styled.div<any>`
  flex: 1;
`
export const TopCollectionName = styled.h4<any>`
  color: ${({ theme }) => theme.sectionTitleColor};
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-clamp: 1;
  height: 24px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  word-break: break-word;
  ${media.sm`
  margin:0;
  font-size:15px;
  `}
`
export const TopCollectionPrice = styled.p<any>`
  color: ${customColors.textColor};
  margin-right: 10px !important;
  font-size: 16px;
  span {
    color: ${({ theme }) => theme.sectionTitleColor};
    margin-left: 5px !important;
  }
  ${media.sm`
  font-size:13px;
  `}
`

export const TopCollectionCardPrice = styled.div<any>`
  text-align: right;
`
export const TopCollectionAPY = styled.p<any>`
  color: ${themeDark.brandColor};
  margin-bottom: 10px;
  font-size: 16px;
  ${media.sm`
  font-size:13px;
  `}
`

export const TopCollectionCardETHPrice = styled.p<any>`
  span {
    color: ${({ theme }) => theme.sectionTitleColor};
    margin-left: 5px !important;
  }
  font-size: 16px;
  ${media.sm`
  font-size:13px;
  `}
`

export const ViewButton = styled.div<any>`
  margin-top: 50px auto auto auto;
  display: grid;
  place-items: center;
`
export const SectionHeader = styled.h1<any>`
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: ${({ theme }) => theme.sectionTitleColor};
  @media (max-width: 800px) {
    margin-top: 24px;
  }
`
