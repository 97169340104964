import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'

export const CategoriesLayout = styled.div<any>`
  margin: 60px 0;
`
export const CategoriesContainer = styled.div<any>`
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px 0 10px 0;
`
export const CategoryCard = styled.div<any>`
  border: 1px solid ${themeDark.brandColor};
  border-radius: 20px;
  width: 410px;
  height: 196px;
  margin: auto auto 20px auto;
  border: 1px solid ${themeDark.brandColor};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all linear 0.2s;
  cursor: pointer;
  :hover {
    scale: 1.02;
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light'
        ? `0px 0px 10px ${themeLight.hoverShadow}`
        : `0px 0px 20px ${themeDark.hoverShadow}`};
  }
`
export const CategoryCardHeader = styled.div<any>`
  background: ${({ theme }) => theme.categoriesCardBg};
  width: 100%;
  height: 65%;
  display: grid;
  place-items: center;
`
export const CategoryCardFooter = styled.div<any>`
  background: ${({ theme }) => theme.categoriesCardFooterBg};
  color: ${themeDark.white};
  width: 100%;
  height: 35%;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 22px;
  box-shadow: 0px -4px 19px rgba(150, 150, 150, 0.25);
`
