import { useAppSelector } from 'hooks/useAppSelector'
import Link from 'next/link'
import { PrimaryButton, SecondaryButton } from '../../styles/defaultStyles'
import {
  BannerButtons,
  BannerDescription,
  BannerLayout,
  BannerLayoutLeft,
  BannerLayoutRight,
  BannerText,
} from './Banner.style'
import { MeetJungleVideo } from './MeetJungle.style'

export const Banner = () => {
  const { theme } = useAppSelector(state => state.theme)

  return (
    <BannerLayout>
      <BannerLayoutLeft>
        <BannerText>
          Discover, Collect and Sell Extraordinary
          <span> NFT’S</span>
        </BannerText>
        <BannerDescription>
          Jungle is one of the largest NFT marketplace available
        </BannerDescription>
        <BannerButtons>
          <Link href="/explore-collections">
            <PrimaryButton margin="0 10px 0 0">Explore</PrimaryButton>
          </Link>
          <Link href="/create">
            <SecondaryButton>Create</SecondaryButton>
          </Link>
        </BannerButtons>
      </BannerLayoutLeft>
      <BannerLayoutRight themeMode={theme}>
        <MeetJungleVideo
          poster="https://jungle-development-bucket.s3.amazonaws.com/profile/1661514562018-pexels-david-ria%C3%B1o-cort%C3%A9s-975771.jpg"
          controls
        >
          <source
            src="https://jungle-development-bucket.s3.amazonaws.com/profile/1661514527324-videoplayback%20%281%29.mp4"
            type="video/mp4"
          />
        </MeetJungleVideo>
      </BannerLayoutRight>
    </BannerLayout>
  )
}

