import NextImage from 'shared/components/nextImage/NextImage'
import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'hooks/useAppSelector'
import SelectField from '../../shared/components/selectField/SelectField'
import { SelectFieldStyle } from '../../shared/components/selectField/SelectFieldStyle'
import { eth_small, polygon_small } from '../../shared/icons'
import { FlexBox, SecondaryButton } from '../../styles/defaultStyles'
import {
  TopCollectionAPY,
  TopCollectionCard,
  TopCollectionCardETHPrice,
  TopCollectionCardImage,
  TopCollectionCardInfo,
  TopCollectionCardPrice,
  TopCollectionName,
  TopCollectionPrice,
  TopCollectionsContainer,
  TopCollectionsLayout,
  ViewButton,
  SectionHeader,
} from './TopCollections.style'
import { SelectDropdown } from './Trending.style'
import { useGetTopCollectionQuery } from 'logic/reactQuery/collectionsService'
import { useRouter } from 'next/router'
import Link from 'next/link'
import LoadingTopCollection from 'shared/components/loader/LoadingTopCollection'

export const TopCollections = () => {
  const router = useRouter()
  const [selectTimePeriod, setSelectTimePeriod] = useState('last7days')
  const [selectValue, setSelectValue] = useState('')
  const [selectVolume, setSelectVolume] = useState('')

  const { data, isLoading }: any = useGetTopCollectionQuery(selectTimePeriod)
  const { homePageStyles } = SelectFieldStyle()
  const { theme } = useAppSelector(state => state.theme)
  const handleSelectTimePeriod = useCallback(
    (info: any) => {
      setSelectTimePeriod(info.value)
    },
    [setSelectTimePeriod],
  )

  useEffect(() => {
    if (selectTimePeriod === 'last24hours') {
      setSelectValue('one_day_change')
      setSelectVolume('one_day_volume')
    } else if (selectTimePeriod === 'last7days') {
      setSelectValue('seven_day_change')
      setSelectVolume('seven_day_volume')
    } else if (selectTimePeriod === 'last30days') {
      setSelectValue('thirty_day_change')
      setSelectVolume('thirty_day_volume')
    }
  }, [selectTimePeriod])
  const options = [
    {
      value: 'last24hours',
      label: 'Last 24 hours',
    },
    {
      value: 'last7days',
      label: 'Last 7 days',
    },
    {
      value: 'last30days',
      label: 'Last 30 days',
    },
  ]

  return (
    <TopCollectionsLayout>
      <FlexBox align="center" justify="center">
        <SectionHeader>Top Collection</SectionHeader>
      </FlexBox>
      <SelectDropdown>
        <SelectField
          options={options}
          handleSelectChange={handleSelectTimePeriod}
          selectedValue={selectTimePeriod}
          dropdownIndicator={true}
          customStyles={homePageStyles}
        />
      </SelectDropdown>
      <TopCollectionsContainer>
        {Array.isArray(data?.message) &&
          data?.message?.length > 0 &&
          data?.message?.slice(0, 15)?.map((card: any) => (
            <TopCollectionCard
              key={card?.id}
              onClick={() => {
                router.push(`/collections/${card?.slug}`)
              }}
            >
              <TopCollectionCardImage themeMode={theme}>
                {card?.logo && <NextImage width={60} height={60} src={card?.logo} alt="" />}
                {card?.isVerified && <span className="icons"></span>}
              </TopCollectionCardImage>
              <TopCollectionCardInfo>
                <TopCollectionName title={card?.name}>{card?.name}</TopCollectionName>
                <TopCollectionPrice>
                  Floor price:{' '}
                  <NextImage
                    src={
                      card?.blockchain === 'POLYGON'
                        ? polygon_small
                        : card?.blockchain === 'Ethereum'
                          ? eth_small
                          : eth_small
                    }
                  />
                  <span>
                    {card?.stats?.floor_price
                      ? parseFloat(card?.stats?.floor_price).toFixed(3)
                      : '0'}
                  </span>
                </TopCollectionPrice>
              </TopCollectionCardInfo>
              <TopCollectionCardPrice>
                <TopCollectionAPY>
                  + {parseFloat(card?.stats?.[selectValue]).toFixed(3)}%
                </TopCollectionAPY>
                <TopCollectionCardETHPrice>
                  <NextImage
                    src={
                      card?.blockchain === 'POLYGON'
                        ? polygon_small
                        : card?.blockchain === 'Ethereum'
                          ? eth_small
                          : eth_small
                    }
                    alt=""
                  />
                  <span>{parseFloat(card?.stats?.[selectVolume]).toFixed(3)}</span>
                </TopCollectionCardETHPrice>
              </TopCollectionCardPrice>
            </TopCollectionCard>
          ))}
        {isLoading && Array.from({ length: 9 }, (x, v) => <LoadingTopCollection />)}
      </TopCollectionsContainer>
      <ViewButton>
        <Link href="/rankings">
          <SecondaryButton>View Rankings</SecondaryButton>
        </Link>
      </ViewButton>
    </TopCollectionsLayout>
  )
}


