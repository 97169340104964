import NextImage from 'shared/components/nextImage/NextImage'
import { useId } from 'react'
import { useAppSelector } from 'hooks/useAppSelector'
import {
  collectionDark,
  collectionLight,
  nftDark,
  nftLight,
  saleDark,
  saleLight,
  walletDark,
  walletLight,
} from '../../shared/icons'
import { FlexBox, SectionHeader } from '../../styles/defaultStyles'
import {
  CreateAndSellCard,
  CreateAndSellCardHeading,
  CreateAndSellCardImage,
  CreateAndSellCardImageBg,
  CreateAndSellCardImageDescription,
  CreateAndSellContainer,
  CreateAndSellLayout,
} from './CreateAndSell.style'

export const CreateAndSell = () => {
  const { theme } = useAppSelector(state => state.theme)
  const createAndSellInfo = [
    {
      id: useId(),
      iconDark: walletDark,
      iconLight: walletLight,
      heading: 'Set up your wallet',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,`,
    },
    {
      id: useId(),
      iconDark: collectionDark,
      iconLight: collectionLight,
      heading: 'Create your collection',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,`,
    },
    {
      id: useId(),
      iconDark: nftDark,
      iconLight: nftLight,
      heading: 'Add your NFTs',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,`,
    },
    {
      id: useId(),
      iconDark: saleDark,
      iconLight: saleLight,
      heading: 'List them for sale',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,`,
    },
  ]

  return (
    <CreateAndSellLayout>
      <FlexBox align="center" justify="center">
        <SectionHeader>Create and sell your NFTs</SectionHeader>
      </FlexBox>
      <CreateAndSellContainer>
        {createAndSellInfo.map((info: any) => (
          <CreateAndSellCard key={info.id}>
            <CreateAndSellCardImageBg themeMode={theme}>
              <CreateAndSellCardImage>
                <NextImage
                  src={theme === 'light' ? info.iconLight : info.iconDark}
                  alt={info.heading}
                />
              </CreateAndSellCardImage>
            </CreateAndSellCardImageBg>
            <CreateAndSellCardHeading themeMode={theme}>{info.heading}</CreateAndSellCardHeading>
            <CreateAndSellCardImageDescription themeMode={theme}>
              {info.description}
            </CreateAndSellCardImageDescription>
          </CreateAndSellCard>
        ))}
      </CreateAndSellContainer>
    </CreateAndSellLayout>
  )
}

