import styled from 'styled-components'
import { animateHover } from 'styles/animations'
import { themeDark, themeLight } from 'styles/theme'
import { media } from '../../utilities/helpers'

export const CreateAndSellLayout = styled.div<any>`
  margin: 50px 0 20px 0;

  ${media.md`
    margin: 100px 0 20px 0;
  `}
`
export const CreateAndSellContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 80px 0 50px 0;
`
export const CreateAndSellCard = styled.div<any>`
  width: 300px;
  height: 300px;
  background: ${({ theme }) => theme.createAndSellCardBg};
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  position: relative;
  margin-bottom: 55px;
  ${media.md`
  width : 100%;
 `}
  &:hover {
    animation: ${animateHover} ease-in-out infinite 2s;
  }
`
export const CreateAndSellCardImage = styled.div<any>`
  background-color: ${({ theme }) => theme.createAndSellCardIconBg};
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: grid;
  place-items: center;
`
export const CreateAndSellCardImageBg = styled.div<any>`
  width: 74px;
  height: 74px;
  background: ${({ themeMode }) => (themeMode === 'light' ? themeDark.white : themeLight.text)};
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: absolute;
  top: -37px;
  right: 18px;
  box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.25);
`
export const CreateAndSellCardHeading = styled.h3<any>`
  letter-spacing: 0.111872px;
  font-weight: 700;
  font-size: 18px;
  margin: 20px 0 10px 0;
  /* color: ${({ theme }) => theme.sectionTitleColor}; */
  color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.borderColor : themeDark.text)};
`
export const CreateAndSellCardImageDescription = styled.p<any>`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.111872px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.borderColor : themeDark.text)};
`
