import SliderNextArrow from './SliderNextArrow'
import SliderPrevArrow from './SliderPrevArrow'

export const notableDropsSlider = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
export const trendingSlider = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
  ],
}

export const collectionResultSlider = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  nextArrow: <SliderNextArrow topPosition="top" />,
  prevArrow: <SliderPrevArrow topPosition="top" />,
  rows: 1,
  // responsive: [
  //   {
  //     breakpoint: 1400,
  //     settings: {
  //       infinite: false,
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //       dots: true,
  //     },
  //   },
  //   {
  //     breakpoint: 992,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //       initialSlide: 2,
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //     },
  //   },
  // ],
}

export const previewSliderSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  // nextArrow: <SliderNextArrow />,
  // prevArrow: <SliderPrevArrow />,
}
export const gasFreeSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
      },
    },
  ],
}
