import { FlexBox, SectionHeader } from '../../styles/defaultStyles'
import {
  NotableCard,
  NotableCardFooter,
  NotableCardFooterDescription,
  NotableCardFooterHeading,
  NotableCardHeader,
  NotableContainer,
  NotableLayout,
  SliderWrapper,
} from './NotableDrops.style'

import Slider from 'react-slick'
import { notableDropsSlider } from '../../shared/components/sliderArrows/sliderConfig'
import { useGetNotableDropsQuery } from 'logic/reactQuery/homePageService'
import { useRouter } from 'next/router'
import { useAppSelector } from 'hooks/useAppSelector'
import LoadingNotableCard from 'shared/components/loader/LoadingNotableCard'

export const NotableDrops = () => {
  const { data, isLoading }: any = useGetNotableDropsQuery()
  const { theme } = useAppSelector(state => state.theme)
  const router = useRouter()

  return (
    <>
      {data && Array.isArray(data?.message) && data?.message[0]?.length === 0 ? (
        <></>
      ) : (
        <NotableLayout>
          <NotableContainer>
            <FlexBox align="center" justify="center">
              <SectionHeader>Notable Drops</SectionHeader>
            </FlexBox>
            <SliderWrapper>
              <Slider {...notableDropsSlider}>
                {data &&
                  data?.message &&
                  data?.message[0]?.slice(0, 20)?.map((card: any) => (
                    <NotableCard
                      key={card?.id}
                      backgroundImage={card?.banner ?? card?.logo}
                      onClick={() => router.push(`/collections/${card?.slug}`)}
                    >
                      <NotableCardHeader themeMode={theme}>Live</NotableCardHeader>
                      <NotableCardFooter>
                        <NotableCardFooterHeading title={card?.name}>
                          {card?.name}
                        </NotableCardFooterHeading>
                        <NotableCardFooterDescription title={card?.description}>
                          {card?.description}
                        </NotableCardFooterDescription>
                      </NotableCardFooter>
                    </NotableCard>
                  ))}
                {isLoading && Array.from({ length: 4 }, (x, v) => <LoadingNotableCard />)}
              </Slider>
            </SliderWrapper>
          </NotableContainer>
        </NotableLayout>
      )}
    </>
  )
}

