import styled from 'styled-components'
import { customColors, themeDark, themeLight } from 'styles/theme'
import { media } from '../../utilities/helpers'

export const TrendingLayout = styled.div<any>``

export const SelectDropdown = styled.div<any>`
  display: grid;
  place-items: center;
  margin: 30px 0;
`

export const TrendingContainer = styled.div<any>`
  text-align: center;
  cursor: pointer;
`

export const TrendingCard = styled.div<any>`
  border: 2px solid ${themeDark.brandColor};
  border-radius: 20px;
  width: 410px !important;
  height: 400px;
  margin: 20px 0;
  overflow: hidden !important;
  position: relative;
  transition: all linear 0.2s;
  :hover {
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light'
        ? `0px 0px 10px ${themeLight.hoverShadow}`
        : `0px 0px 25px ${themeDark.hoverShadow}`};
    scale: 0.98;
  }
  ${media.md`
      width : 100% !important;
     `}

  ${media.sx`
      margin-bottom: 60px;
  `}
`

export const TrendingCardTop = styled.div<any>`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80%;
  position: relative;
`

export const TrendingCardBottom = styled.div<any>`
  /* padding: 15px; */
  height: max-content;
  background: ${({ theme }) => theme.trendingCardBg};
  text-align: initial !important;
`

export const TrendingCardProfileInfo = styled.div<any>`
  background: ${customColors.greenGradient};
  align-items: center;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: 1fr 4fr 120px;
  gap: 4px;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: initial !important;
`
export const TrendingCardDescription = styled.p<any>`
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.111872px;
  height: 86px;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden !important;
`

export const TrendingCardProfileLeft = styled.div<any>`
  width: 50px;
  height: 50px;
  border: 1px solid ${themeDark.borderPrimary};
  border-radius: 50%;
  margin-right: 15px;
  overflow: hidden;
`
export const TrendingCardProfileRight = styled.div<any>``
export const TrendingCardProfileName = styled.h4<any>`
  font-weight: 600;
  font-size: 16px;
  color: ${themeDark.white};
  line-clamp: 1;
  height: 24px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
`
export const TrendingCardProfileDescription = styled.p<any>`
  font-weight: 400;
  font-size: 14px;
  color: ${themeDark.white};
  cursor: pointer;
`
export const CashbackButton = styled.button<any>`
  background: ${themeLight.aiWarning};
  border-radius: 16px;
  /* width: max-content; */
  border: none;
  font-size: 12px;
  line-height: 18px;
  color: ${themeLight.text};
  padding: 5px 12px;
`
