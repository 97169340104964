import { Banner } from 'modules/homePage/Banner'
import { Categories } from 'modules/homePage/Categories'
import { CreateAndSell } from 'modules/homePage/CreateAndSell'
import { MeetJungle } from 'modules/homePage/MeetJungle'
import { NotableDrops } from 'modules/homePage/NotableDrops'
import { TopCollections } from 'modules/homePage/TopCollections'
import { Trending } from 'modules/homePage/Trending'
import type { NextPage } from 'next'
import { OpacityAnimation } from 'styles/sharedStyles'
import HeaderMetaInfo from '../shared/headerMetaInfo/HeaderMetaInfo'
import { Container } from '../styles/defaultStyles'

const Home: NextPage = () => {
  return (
    <>
      <HeaderMetaInfo pageTitle="Jungle" />
      <Container>
        <OpacityAnimation>
          <Banner />
          <NotableDrops />
          <TopCollections />
          <Trending />
          <CreateAndSell />
          <Categories />
          <MeetJungle />
        </OpacityAnimation>
      </Container>
    </>
  )
}

export default Home
