import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
export const MeetJungleLayout = styled.div<any>`
  margin: 10px 0 70px 0;
`
export const MeetJungleContainer = styled.div<any>`
  filter: drop-shadow(-4px -4px 4px rgba(170, 170, 170, 0.25))
    drop-shadow(6px 4px 18px rgba(179, 179, 179, 0.45));
  height: 640px;
  margin: 50px 0 124px 0;
  overflow: hidden;
  border-radius: 40px !important;
  transition: all linear 0.2s;
  :hover {
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light' ? `0px 1px 18px ${themeLight.hoverShadow}` : `0px 2px 26px ${themeDark.hoverShadow}`};
  }
`
export const MeetJungleVideo = styled.video<any>`
  object-fit: cover;
  width: 100%;
  height: 100%;
`
